import styled from "styled-components";
import {Link} from "gatsby";

export const Button = styled(Link)`
  display: inline-block;
  font-family: ${({theme}) => theme.fontFamily.balooTamma2};
  font-weight: ${({theme}) => theme.fontWeight.semiBold};
  font-size: 14px;
  line-height: 16px;
  color: ${({theme}) => theme.color.white};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  background-color: ${({theme}) => theme.color.lightBlue};
  border: none;
  border-radius: 3px;
  outline: 0;
  box-sizing: border-box;
  transition: ${({theme}) => theme.transition.md};
  box-shadow: ${({theme}) => theme.boxShadow.second};
  padding: 14px 71px 11px 30px;
  position: relative;

  span {
    vertical-align: middle;

    &:before {
      content: '';
      display: inline-block;
      width: 41px;
      height: 100%;
      background-color: ${({theme}) => theme.color.lightBlue2};
      border-radius: 0 3px 3px 0;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  svg {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
`;