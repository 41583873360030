import styled from "styled-components";

export const Wrapper = styled.nav`
  margin: ${({highlighted}) => highlighted ? '0 0 20px' : '0'};
  @media (min-width: ${({theme}) => theme.screen.smMin}) {
    margin: ${({highlighted}) => highlighted ? '0 0 15px' : '0'};
  }
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    margin: ${({highlighted}) => highlighted ? '0 0 20px' : '0'};
  }
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    margin: ${({highlighted}) => highlighted ? '0 0 25px' : '0'};
  }
`;

export const Content = styled.div`

`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: ${({theme}) => theme.screen.smMin}) {
    margin: 0;
    display: unset;
    flex-wrap: unset;
  }
`;

export const ListItem = styled.li`
  width: ${({highlighted}) => highlighted ? '100%' : '50%'};
  box-sizing: border-box;
  padding: 0 15px;
  margin: ${({highlighted}) => highlighted ? '0 0 15px' : '0 0 30px'};
  @media (min-width: ${({theme}) => theme.screen.smMin}) {
    width: auto;
    padding: 0;
    margin: 0 0 10px;
  }

  a {
    display: inline-block;
    font-family: ${({theme}) => theme.fontFamily.balooTamma2};
    font-weight: ${({theme}) => theme.fontWeight.regular};
    font-size: 18px;
    line-height: 22px;
    color: ${({theme}) => theme.color.white};
    text-decoration: none;
    transition: ${({theme}) => theme.transition.md};
    @media (min-width: ${({theme}) => theme.screen.smMin}) {
      font-size: 16px;
      line-height: 18px;
    }

    &:hover {
      color: ${({theme}) => theme.color.lightBlue};
    }
  }
`;
