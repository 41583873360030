import styled from "styled-components";

export const Wrapper = styled.header`
  background-color: ${({theme}) => theme.color.white};
  box-shadow: ${({theme}) => theme.boxShadow.first};
`;

export const Container = styled.div`
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0 auto;
  @media (min-width: ${({theme}) => theme.screen.xsMin}) {
    width: 540px;
  }
  @media (min-width: ${({theme}) => theme.screen.smMin}) {
    width: 750px;
  }
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    width: 970px;
  }
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    width: 1170px;
  }
  @media (min-width: ${({theme}) => theme.screen.xlgMin}) {
    width: 1540px;
    padding: 0 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 33px 0 26px;
  transition: ${({theme}) => theme.transition.md};
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    align-items: unset;
    padding: 50px 0 0;
  }
  @media (min-width: ${({theme}) => theme.screen.xlgMin}) {
    padding: 60px 0 0;
  }
`;