import React from "react";
import {Button} from "./ButtonLightBlue.styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";

const ButtonLightBlue = ({children, to}) => {
  return (
    <Button to={to}>
      <span>{children}</span>
      <FontAwesomeIcon icon={faArrowCircleRight} width="14" height="14"/>
    </Button>
  );
}

export default ButtonLightBlue;