import React from "react";
import {Link} from "gatsby";
import Container from "../Container/Container";
import {Wrapper, Content, Text} from "./FooterCookies.styles";

const FooterCookies = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Text>
            Korzystając z tej strony zgadzasz się na wykorzystywanie ciasteczek (plików cookie) umieszczanych w Twojej
            przeglądarce. <Link to="#">Szczegóły >></Link>
          </Text>
        </Content>
      </Container>
    </Wrapper>
  );
}

export default FooterCookies;