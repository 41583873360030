import React from "react";
import {Wrapper, Container, Content} from "./Header.styles";
import Logo from "../Logo/Logo";
import HeaderMenu from "../HeaderMenu/HeaderMenu";

const Header = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Logo/>
          <HeaderMenu/>
        </Content>
      </Container>
    </Wrapper>
  );
}

export default Header;