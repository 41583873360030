import {useStaticQuery, graphql} from "gatsby";

export const usePrivacyPageLinkQuery = () => {
  const data = useStaticQuery(graphql`
    query PrivacyPageLinkQuery {
      wpPage(isPrivacyPage: {eq: true}) {
        uri
      }
    }
  `);

  return data;
}