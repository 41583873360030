import React from "react";
import {Wrapper, Content} from "./FooterWrapper1.styles";
import FooterMenu from "../FooterMenu/FooterMenu";
import {useFooterMenu1Query} from "../../hooks/useFooterMenu1Query";

const FooterWrapper1 = () => {
  const {wpMenu} = useFooterMenu1Query();

  return (
    <Wrapper>
      <Content>
        <FooterMenu menuItems={wpMenu.menuItems}/>
      </Content>
    </Wrapper>
  );
}

export default FooterWrapper1;