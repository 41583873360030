import styled, {createGlobalStyle} from "styled-components";
import {normalize} from "styled-normalize";

export const theme = {
  fontFamily: {
    notoSans: "'Noto Sans', 'Arial', sans-serif",
    gotham: "'Gotham', 'Arial', sans-serif",
    balooTamma2: "'Baloo Tamma 2', 'Arial', cursive"
  },
  fontWeight: {
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700
  },
  fontSize: {
    sm: '16px'
  },
  lineHeight: {
    body: 1.3
  },
  color: {
    white: '#ffffff',
    gray: '#b7b7b7',
    darkGray: '#b4b3b3',
    brightGray: '#e9edef',
    lightGray: '#f5f5f5',
    graphite: '#57687d',
    blue: '#1f4b6c',
    darkBlue: '#10233b',
    lightBlue: '#70c7d9',
    lightBlue2: '#64bcce'
  },
  boxShadow: {
    first: '1px 3px 10px 1px rgb(183 183 183 / 30%)',
    second: '2px 4px 8px 2px rgb(0 0 0 / 8%)'
  },
  transition: {
    lg: '500ms',
    md: '300ms',
    sm: '200ms',
    xs: '100ms'
  },
  screen: {
    xxlgMin: '1920px',
    xxlgMax: '1919px',
    xlgMin: '1600px',
    xlgMax: '1599px',
    lgMin: '1200px',
    lgMax: '1199px',
    mdMin: '992px',
    mdMax: '991px',
    smMin: '768px',
    smMax: '767px',
    xsMin: '576px',
    xsMax: '575px'
  }
};

export const GlobalStyles = createGlobalStyle`
  ${normalize}
  body {
    font-family: ${theme.fontFamily.notoSans};
    font-weight: ${theme.fontWeight.light};
    font-size: ${theme.fontSize.sm};
    line-height: ${theme.lineHeight.body};
    color: ${theme.color.graphite};
  }
`;

export const Wrapper = styled.main`

`;