import styled from "styled-components";

export const Wrapper = styled.nav`
  width: 100%;
  box-sizing: border-box;
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    padding: 8px 0 0 20px;
  }
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    padding: 8px 0 0 45px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    justify-content: space-between;
    box-sizing: border-box;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    padding: 0;
    display: flex;
    margin: 0 -6px;
  }
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    margin: 0 -5px;
  }
`;

export const ListItem = styled.li`
  padding: 0 5px 4px;
  a {
    display: block;
    box-sizing: border-box;
    font-family: ${({theme}) => theme.fontFamily.balooTamma2};
    font-weight: ${({theme}) => theme.fontWeight.medium};
    font-size: 20px;
    line-height: 26px;
    color: ${({theme}) => theme.color.darkBlue};
    text-decoration: none;
    transition: ${({theme}) => theme.transition.md};
    padding: 8px 0 4px;
    position: relative;
    @media (min-width: ${({theme}) => theme.screen.mdMin}) {
      display: inline-block;
      font-size: 14px;
      line-height: 23px;
      border-bottom: 3px solid ${({theme}) => theme.color.white};
      padding: 0 10px 15px 5px;
    }
    @media (min-width: ${({theme}) => theme.screen.lgMin}) {
      font-size: 15px;
      line-height: 24px;
      padding: 0 21px 25px 15px;
    }
    @media (min-width: ${({theme}) => theme.screen.xlgMin}) {
      font-size: 16px;
      line-height: 25px;
    }
    svg {
      visibility: hidden;
      position: absolute;
      right: -2px;
      top: 3px;
      @media (min-width: ${({theme}) => theme.screen.lgMin}) {
        right: 9px;
      }
      &.fa-angle-right {
        visibility: visible;
      }
    }
    &:hover {
      border-color: ${({theme}) => theme.color.darkBlue};
      svg {
        &.fa-angle-right {
          visibility: hidden;
        }
        &.fa-angle-down {
          visibility: visible;
        }
      }
    }
  }
`;
