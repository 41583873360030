import React, {useContext} from "react";
import {ThemeContext} from "styled-components";
import {Link} from "gatsby";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {Wrapper, Content, List, ListItem} from "./HeaderMenu.styles";
import {useHeaderMenuQuery} from "../../hooks/useHeaderMenuQuery";

const HeaderMenu = () => {
  const theme = useContext(ThemeContext);
  const {wpMenu} = useHeaderMenuQuery();

  return (
    <Wrapper>
      <Content>
        <List>
          {wpMenu.menuItems.nodes.map((menuItem) => (
            !menuItem.parentId ? (
              <ListItem key={menuItem.id}>
                <Link to={menuItem.url} activeClassName="active">
                  {menuItem.label}
                  <FontAwesomeIcon icon={faAngleRight} width="7" height="14" size="sm" color={theme.color.lightBlue}/>
                  <FontAwesomeIcon icon={faAngleDown} width="9" height="14" size="sm" color={theme.color.lightBlue}/>
                </Link>
              </ListItem>
            ) : null
          ))}
        </List>
      </Content>
    </Wrapper>
  );
}

export default HeaderMenu;