import React from "react";
import {Wrapper, Content} from "./FooterWrapper2.styles";
import {useFooterMenu2Query} from "../../hooks/useFooterMenu2Query";
import {usePrivacyPageLinkQuery} from "../../hooks/usePrivacyPageLinkQuery";
import FooterMenu from "../FooterMenu/FooterMenu";
import ButtonLightBlue from "../ButtonLightBlue/ButtonLightBlue";
import FooterSocial from "../FooterSocial/FooterSocial";

const FooterWrapper2 = ({highlighted}) => {
  const {wpMenu} = useFooterMenu2Query();
  const {wpPage} = usePrivacyPageLinkQuery();

  return (
    <Wrapper>
      <Content>
        <FooterMenu menuItems={wpMenu.menuItems} highlighted={highlighted}/>
        <ButtonLightBlue to={wpPage.uri}>Kontakt</ButtonLightBlue>
        <FooterSocial/>
      </Content>
    </Wrapper>
  );
}

export default FooterWrapper2;