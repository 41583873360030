import {useStaticQuery, graphql} from "gatsby";

export const useSocialMediaLinkQuery = () => {
  const data = useStaticQuery(graphql`
    query SocialMediaLinkQuery {
      wp {
        crbThemeOptions {
          socialMediaFacebookLink
          socialMediaLinkedinLink
        }
      }
    }
  `);

  return data;
}