import {useStaticQuery, graphql} from "gatsby";

export const useHeaderMenuQuery = () => {
  const data = useStaticQuery(graphql`
    query HeaderMenuQuery {
      wpMenu(locations: {eq: HEADER_MENU}) {
        menuItems {
          nodes {
            label
            url
            id
            parentId
          }
        }
      }
    }
  `);

  return data;
}