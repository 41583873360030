import styled from "styled-components";

export const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0 auto;
  @media (min-width: ${({theme}) => theme.screen.xsMin}) {
    width: 540px;
  }
  @media (min-width: ${({theme}) => theme.screen.smMin}) {
    width: 750px;
  }
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    width: 920px;
  }
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    width: 1100px;
  }
  @media (min-width: ${({theme}) => theme.screen.xlgMin}) {
    width: 1425px;
    padding: 0 20px;
  }
`;