import styled, {css} from "styled-components";

export const Wrapper = styled.footer`
  overflow: hidden;
  background-color: ${({theme}) => theme.color.darkBlue};
`;

export const Content = styled.div`
  @media (min-width: ${({theme}) => theme.screen.smMin}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Column = styled.div`
  position: ${({highlighted}) => highlighted ? 'relative' : 'static'};
  box-sizing: border-box;
  padding: ${({highlighted}) => highlighted ? '40px 0' : '40px 0 15px'};
  background-color: ${({highlighted, theme}) => highlighted ? theme.color.blue : theme.color.darkBlue};
  @media (min-width: ${({theme}) => theme.screen.smMin}) {
    width: ${({highlighted}) => highlighted ? '40%' : '30%'};
    padding: 35px 0 30px;
  }
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    width: 33.33333%;
    padding: 40px 0 30px;
  }
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    padding: 46px 0 35px;
  }
  ${({highlighted}) => highlighted && css`
    &::before,
    &::after {
      @media (max-width: ${({theme}) => theme.screen.smMax}) {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: #1f4b6c;
        position: absolute;
        top: 0;
      }
    }

    &::before {
      @media (max-width: ${({theme}) => theme.screen.smMax}) {
        left: 2px;
        transform: translateX(-100%);
      }
    }

    &::after {
      @media (max-width: ${({theme}) => theme.screen.smMax}) {
        right: 2px;
        transform: translateX(100%);
      }
    }
  `}
`;