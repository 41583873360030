import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 0 0 15px;
  @media (min-width: ${({theme}) => theme.screen.smMin}) {
    padding: 0 0 0 25px;
  }
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    padding: 0 0 0 80px;
  }
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    padding: 0 0 0 100px;
  }
  @media (min-width: ${({theme}) => theme.screen.xlgMin}) {
    padding: 0 0 0 123px;
  }
`;

export const Content = styled.div`

`;