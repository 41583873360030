import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${({theme}) => theme.color.lightGray};
`;

export const Content = styled.div`

`;

export const Text = styled.p`
  font-family: ${({theme}) => theme.fontFamily.balooTamma2};
  font-weight: ${({theme}) => theme.fontWeight.regular};
  font-size: 15px;
  line-height: 20px;
  color: ${({theme}) => theme.color.graphite};
  padding: 22px 0 18px;
  margin: 0;
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    padding: 25px 0 21px;
  }

  a {
    font-weight: ${({theme}) => theme.fontWeight.medium};
    color: ${({theme}) => theme.color.graphite};
    text-decoration: none
  }
`;