import React from "react";
import {Wrapper, Heading} from "./Logo.styles";
import {Link} from "gatsby";
import LogoSVG from "../../assets/images/svgs/logo.svg";

const Logo = () => {
  return (
    <Wrapper>
      <Heading>
        <span>Wybierz jedną z tanich lub darmowych domen</span>
        <Link to="/">
          <LogoSVG/>
        </Link>
      </Heading>
    </Wrapper>
  );
}

export default Logo;