import styled from "styled-components";

export const Heading = styled.p`
  font-family: ${({theme}) => theme.fontFamily.balooTamma2};
  font-weight: ${({theme}) => theme.fontWeight.regular};
  font-size: 26px;
  line-height: 30px;
  color: ${({theme}) => theme.color.white};
  margin: 0 0 30px;
  @media (min-width: ${({theme}) => theme.screen.smMin}) {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 25px;
  }
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    font-size: 20px;
    line-height: 24px;
    margin: 0 0 30px;
  }
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    margin: 0 0 35px;
  }
`;