import styled from "styled-components";

export const Wrapper = styled.div`
  @media (min-width: ${({theme}) => theme.screen.smMin}) {
    margin: 25px 0 0;
  }
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    margin: 30px 0 0;
  }
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    margin: 35px 0 0;
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const Link = styled.a`
  display: inline-block;
  color: ${({theme}) => theme.color.white};
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border: 2px solid ${({theme}) => theme.color.white};
  border-radius: 50%;
  margin: 0 20px 0 0;
  position: relative;

  &:last-of-type {
    margin: 0;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;