import styled from "styled-components";

export const Wrapper = styled.div`
`;

export const Heading = styled.h1`
  margin: 0;

  span {
    display: inline-block;
    text-indent: -99999em;
  }

  a {
    display: inline-block;
    outline: none;

    svg {
      width: 125px;
      height: auto;
      @media (min-width: ${({theme}) => theme.screen.xsMin}) {
        width: 170px;
      }
      @media (min-width: ${({theme}) => theme.screen.mdMin}) {
        width: 110px;
      }
      @media (min-width: ${({theme}) => theme.screen.lgMin}) {
        width: 120px;
      }
      @media (min-width: ${({theme}) => theme.screen.xlgMin}) {
        width: 125px;
      }
    }
  }
`;