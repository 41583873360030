import React from "react";
import {Wrapper, Content} from "./FooterWrapper3.styles";
import {useFooterMenu3Query} from "../../hooks/useFooterMenu3Query";
import FooterMenu from "../FooterMenu/FooterMenu";

const FooterWrapper3 = () => {
  const {wpMenu} = useFooterMenu3Query();

  return (
    <Wrapper>
      <Content>
        <FooterMenu menuItems={wpMenu.menuItems}/>
      </Content>
    </Wrapper>
  );
}

export default FooterWrapper3;