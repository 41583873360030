import React from "react";
import Container from "../Container/Container";
import {Wrapper, Content, Column} from "./Footer.styles";
import FooterWrapper1 from "../FooterWrapper1/FooterWrapper1";
import FooterWrapper2 from "../FooterWrapper2/FooterWrapper2";
import FooterWrapper3 from "../FooterWrapper3/FooterWrapper3";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Column>
            <FooterWrapper1/>
          </Column>
          <Column highlighted>
            <FooterWrapper2 highlighted/>
          </Column>
          <Column>
            <FooterWrapper3/>
          </Column>
        </Content>
      </Container>
    </Wrapper>
  );
}

export default Footer;