import {useStaticQuery, graphql} from "gatsby";

export const useFooterMenu1Query = () => {
  const data = useStaticQuery(graphql`
    query FooterMenu1Query {
      wpMenu(locations: {eq: FOOTER_MENU_1}) {
        menuItems {
          nodes {
            label
            url
            id
            parentId
            childItems {
              nodes {
                label
                url
                id
              }
            }
          }
        }
      }
    }
  `);

  return data;
}