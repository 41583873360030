import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 0 0 15px;
  @media (min-width: ${({theme}) => theme.screen.smMin}) {
    padding: 0 20px;
  }
  @media (min-width: ${({theme}) => theme.screen.mdMin}) {
    padding: 0 0 0 30px;
  }
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    padding: 0 0 0 52px;
  }
`;

export const Content = styled.div`

`;