import React from "react";
import {Wrapper} from "./Container.styles";

const Footer = ({children}) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}

export default Footer;