import React from "react";
import {Link} from "gatsby";
import {Wrapper, Content, List, ListItem} from "./FooterMenu.styles";
import FooterHeading from "../FooterHeading/FooterHeading";

const FooterMenu = ({menuItems, highlighted}) => {
  return (
    <Wrapper highlighted={highlighted}>
      {menuItems.nodes.map((menuItem) => (
        !menuItem.parentId ? (
          <Content key={menuItem.id}>
            <FooterHeading>{menuItem.label}</FooterHeading>
            {menuItem.childItems.nodes.length !== 0 ? (
              <List>
                {menuItem.childItems.nodes.map((childItem) => (
                  <ListItem key={childItem.id} highlighted={highlighted}>
                    <Link to={childItem.url}>
                      {childItem.label}
                    </Link>
                  </ListItem>
                ))}
              </List>
            ) : null}
          </Content>
        ) : null
      ))}
    </Wrapper>
  );
}

export default FooterMenu;