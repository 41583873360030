import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/Layout/Layout";

const WpPage = ({data}) => {
  const {content, seo} = data.wpPage;

  return (
    <>
      <Helmet>
        <meta name="description" content={seo.metaDesc}/>
      </Helmet>
      <Layout dangerouslySetInnerHTML={{__html: content}}/>
    </>
  );
}

export default WpPage;

export const query = graphql`
  query PageById($id: String) {
    wpPage(id: {eq: $id}) {
      title
      content
      seo {
        metaDesc
      }
    }
  }   
`;