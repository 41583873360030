import React from "react";
import {theme, GlobalStyles, Wrapper} from "./Layout.styles";
import {ThemeProvider} from "styled-components";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FooterCookies from "../FooterCookies/FooterCookies";
import FooterCopyright from "../FooterCopyright/FooterCopyright";

const Layout = ({dangerouslySetInnerHTML}) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles/>
      <Header/>
      <Wrapper dangerouslySetInnerHTML={dangerouslySetInnerHTML}></Wrapper>
      <Footer/>
      <FooterCookies/>
      <FooterCopyright/>
    </ThemeProvider>
  );
}

export default Layout;