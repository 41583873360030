import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: ${({theme}) => theme.color.white};
`;

export const Content = styled.div`

`;

export const Text = styled.p`
  font-family: ${({theme}) => theme.fontFamily.notoSans};
  font-weight: ${({theme}) => theme.fontWeight.regular};
  font-size: 12px;
  line-height: 16px;
  color: ${({theme}) => theme.color.gray};
  padding: 17px 0 14px;
  margin: 0;
  @media (min-width: ${({theme}) => theme.screen.lgMin}) {
    padding: 20px 0 17px;
  }
`;