import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {Wrapper, Content, Link} from "./FooterSocial.styles";
import {useSocialMediaLinkQuery} from "../../hooks/useSocialMediaLinkQuery";

const FooterSocial = () => {
  const {wp} = useSocialMediaLinkQuery();

  return (
    <Wrapper>
      <Content>
        <Link href={wp.crbThemeOptions.socialMediaFacebookLink}>
          <FontAwesomeIcon icon={faFacebookF} width="10" height="16"/>
        </Link>
        <Link href={wp.crbThemeOptions.socialMediaLinkedinLink}>
          <FontAwesomeIcon icon={faLinkedinIn} width="14" height="16"/>
        </Link>
      </Content>
    </Wrapper>
  );
}

export default FooterSocial;