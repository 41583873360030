import React from "react";
import Container from "../Container/Container";
import {Wrapper, Content, Text} from "./FooterCopyright.styles";

const FooterCopyright = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <Text>
            © 2021 H88 S.A. All rights reserved
          </Text>
        </Content>
      </Container>
    </Wrapper>
  );
}

export default FooterCopyright;